import {
  SET_COMMON_CONFIG
} from "./actionTypes";

const initialState = {
  device_type: null,
  userLevel: null, 
};

const CommonConfig = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMON_CONFIG:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

export default CommonConfig;

import { call, put, all, takeEvery } from "redux-saga/effects";
// Login Redux States
import { USER_PROFILE } from "./actionTypes";
import { getUserProfileSuccess } from "./actions";
import { makeAjax, showMessage, url } from "../../helpers/global_helper";

function* getUserProfile({ payload: { formData } }) {
  try {

    const response = yield call(
      makeAjax,
      url.PLAYER_API.getUser, {}, url.PLAYER_MS_EXT);

    if (response.status == 'SUCCESS') {
      yield all([
        put(getUserProfileSuccess(response.data.user))
      ]);
      // if (!response.data.user.nickname && window.location.href != window.location.origin + "/nickname") {
      //   // window.location.href = window.location.origin + "/nickname";
      // }
      
      if (response.data.user.nickname) {
        if (window.location.href === window.location.origin + "/nickname") {
          window.location.href = window.location.origin + "/";
        }
      } else {
        if (window.location.href !== window.location.origin + "/nickname") {
          window.location.href = window.location.origin + "/nickname";
        }
      }

    }
    else {
      showMessage(response);
    }
  } catch (error) {

  }
}

function* userProfileSaga() {
  yield takeEvery(USER_PROFILE, getUserProfile);
}
export default userProfileSaga;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";

const Carousel = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [banners, setBanners] = useState([]);

  const settings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    const storedBanners = localStorage.getItem("promoBanners");

    if (storedBanners) {
      setBanners(JSON.parse(storedBanners));
    } else {
      makeAjax(url.PLAYER_API.getPromoBanner, { date: currentDate }, url.PLAYER_MS_EXT).then((response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        localStorage.setItem("promoBanners", JSON.stringify(response.data));
        setBanners(response.data);
      });
    }
  }, []);

  const handleBannerClick = (e) => {
    let redirect_link = e.target.name;
    if (redirect_link == 'promotion') {
      const clickedBanner = banners[activeIndex];
      navigate("/promotion", {
        state: {
          id: clickedBanner.id,
          bannerData: clickedBanner,
          activeState: clickedBanner.id,
        },
      });
    } else if (redirect_link == 'home') {
    } else {
      window.open(`https://${redirect_link}`, '_blank');
    }
  };

  return (
    <div className="mobile_carousel">
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={banner.id} className="carousel-item">
            <img
              src={banner.updated_web_banner}
              alt="Desktop Banner"
              className="desktop_banner_img"
              name={banner.redirect_link}
              onClick={handleBannerClick}
            />
            <img
              src={banner.updated_mobile_banner}
              alt="Mobile Banner"
              className="mobile_portrait_banner_img"
              name={banner.redirect_link}
              onClick={handleBannerClick}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;

import React from "react";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../NumberFormat";

const DetailsMoPopup = (props) => {
  const {
    historyId,
    datetime,
    game_name,
    period,
    status,
    credit,
    debit,
    coin,
    balance,
    act,
  } = props;

  return (
    <div className="poupup_detail_table">
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("history")} ID</div>
        <div className="poupup_detail_table_desc">{historyId}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("date")}</div>
        <div className="poupup_detail_table_desc">{datetime}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("game")}</div>
        <div className="poupup_detail_table_desc">{game_name}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("period")}</div>
        <div className="poupup_detail_table_desc">{period}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("status")}</div>
        <div className="poupup_detail_table_desc">{act}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("credit")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(credit)}
        </div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("debit")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(debit)}
        </div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("coin")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(coin)}
        </div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("balance")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(balance)}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(DetailsMoPopup);

import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Routes, Route } from "react-router-dom";
import { makeAjax, respStatus, showMessage, url } from "../../helpers/global_helper";
import { TOKEN_KEY } from "../../helpers/url_helper";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

const LaunchLobby = (props) => {
  const { token, operatorid, username, channel } = useParams();
  let navigate = useNavigate();
  const [imageLoader, setImageLoader] = useState(null);
  const [logo, setLogo] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  let storedFPJS = localStorage.getItem('fpjs');
  const [fpjs, setFPJS] = useState({
    requestId: "",
    visitorId: "",
    deviceBrowser: "",
  });

  // Function to fetch loader and logo image 
  const fetchImgData = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.text();
      // Loader Image
      const base64Regex1 = /loading_lobby\s*=\s*"([^"]*)"/;
      const match1 = data.match(base64Regex1);
      if (match1 && match1.length > 1) {
        setImageLoader(match1[1]);
      } else {
        // console.error('404 Loader Image not found');
      }
      // Logo Image
      const base64Regex2 = /logo\s*=\s*"([^"]*)"/;
      const match2 = data.match(base64Regex2);
      if (match2 && match2.length > 1) {
        setLogo(match2[1]);
      } else {
        // console.error('404 Logo Image not found');
      }
    } catch (error) {
      // console.error('Error fetching the JS file:', error);
    }
  };

  useEffect(() => {
    if (imageLoader && !hasFetched) {
      localStorage.setItem('loader', imageLoader);
      props.setLoader(imageLoader);
      props.setLoaderSpeed(true);
      setHasFetched(true);
    }
  }, [imageLoader, hasFetched]);

  const checkDevice = () => {
    if (!storedFPJS) return;
    const fp = JSON.parse(storedFPJS);

    makeAjax(url.PLAYER_API.checkDevice, fp, url.PLAYER_MS_EXT)
      .then((response) => {
        if (response.status !== respStatus['SUCCESS']) {
          console.log("CheckDevice Failed with response ", response);
        }
      })
      .catch((error) => {
        console.error('Error in checkDevice:', error);
      });
  };

  // Function to get token and other session data from the API
  const getMyToken = async () => {
    let reqData = {
      token: token,
      operatorid: operatorid,
      user_name: username,
      channel: channel
    };

    if (
      localStorage.getItem("partner_token")
      && localStorage.getItem("partner_token") == token
      && username == localStorage.getItem('partner_username')
      && localStorage.getItem(url.TOKEN_KEY)
    ) {
      navigate('/');
      return;
    }

    const response = await makeAjax(url.PLAYER_API.addUserSession, reqData, url.PLAYER_MS_EXT);
    if (response.data.cgUserBlocked === true) {
      navigate('/accessdenied');
      return;
    }

    if (response.status !== respStatus['SUCCESS']) {
      if (response.code === "lobby_maintenance" || response.code === "inactive_partner") {
        navigate('/maintenance');
        return;
      }
      showMessage(response);
      return;
    }

    await fetchImgData(response.data.loaderJS);
    localStorage.setItem(TOKEN_KEY, response.data.token);
    localStorage.setItem("partner", response.data.partner);
    localStorage.setItem('partner_token', token);
    localStorage.setItem('partner_username', username);
    setUserName(response.data.userName);
    setUserId(response.data.userId);

    const nickresp = await makeAjax(url.PLAYER_API.getUser, {}, url.PLAYER_MS_EXT);
    const nickname = nickresp.data.user.nickname

    if (nickresp.status === respStatus['SUCCESS']) {
      if (nickname === '' || nickname === null) {
        navigate("/nickname");
        // return;
      } else {
        navigate('/');
      }
    }
  };

  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: false }
  );

  useEffect(() => {
    const { requestId, visitorId, browserName, os, osVersion } = data || {};
    const concatenatedValue = `${browserName || ""} ${os || ""} ${osVersion || ""}`;
    setFPJS({
      requestId: requestId || "",
      visitorId: visitorId || "",
      deviceBrowser: concatenatedValue.trim(),
    });
  }, [data]);

  useEffect(() => {
    if (fpjs.requestId) {
      localStorage.setItem("fpjs", JSON.stringify(fpjs));
      storedFPJS = localStorage.getItem('fpjs');
      setTimeout(() => {
        checkDevice();
      }, 7000);
    }
  }, [fpjs]);

  useEffect(() => {
    if (!storedFPJS) {
      if (userId && userName) {
        console.log("Triggering getData...");
        getData({
          linkedId: userId,
          tag: {
            env: "199",
            username: userName,
          }
        });
      }
    }
  }, [userId, username]);


  useEffect(() => {
    getMyToken();
    if (storedFPJS) {
      setTimeout(() => {
        checkDevice();
      }, 7000);
    }
  }, [token, operatorid, username]);

  return null;
};

export default LaunchLobby;

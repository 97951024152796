import React from "react";
import Modal from "../Modal/Modal";
// import InnerPopup from "../Modal/InnerPopup";


const NicknameSuccessPopup = ({ onClose }) => {

  return (
    <div className="nickname_modal">
      <Modal onCloseModal={onClose} closeButton={false} >
        <div className="nickname_content_box">
          <div className="success_message" >
            <p>
            NICKNAME ANDA BERHASIL TERDAFTAR!
            </p>
          </div >
        </div >
      </Modal >
    </div >
  );
};

export default NicknameSuccessPopup;

import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Profile from "../Header/SubComponent/HeaderLeftMenu/Profile";
import NotificationPage from "../NotificationPage/NotificationPage";
import Jackpot from "../Jackpot/Jackpot";
import CoinRewards from "../CoinRewards/CoinRewards";
import InformationMoDesign from "../MobileDesign/InformationMoDesign";
import TableDataInfo from "../Main/SubComponent/TableDataInfo";
import Promotion from "../PromotionPage/PromotionPage";
import { useSelector } from "react-redux";

const NavigationComponent = () => {
  const device = useSelector((state) => state.CommonConfig.device_type);
  const location = useLocation();
  const [direction, setDirection] = useState(""); 
  const [activePage, setActivePage] = useState(location.pathname);
  const isMobile = device === 'Mobile';

  useEffect(() => {
    const routesOrder = [
      "/", "/profile", "/notification", "/information",
      "/promotion", "/jackpot", "/coinReward", "/gamedata"
    ];
    const currentIndex = routesOrder.indexOf(location.pathname);
    if (currentIndex >= 0) {
      setDirection("down");
    } 
    const timeout = setTimeout(() => {
      setActivePage(location.pathname);
    }, 300);

    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <>
      <Header />
      {isMobile && (
        <div className={`pageslider ${direction}`}> 
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={<PageWrapper isActive={activePage === "/"}><Main /></PageWrapper>}
            />
            <Route
              path="/profile"
              element={<PageWrapper isActive={activePage === "/profile"}><Profile /></PageWrapper>}
            />
            <Route
              path="/notification"
              element={<PageWrapper isActive={activePage === "/notification"}><NotificationPage /></PageWrapper>}
            />
            <Route
              path="/information"
              element={<PageWrapper isActive={activePage === "/information"}><InformationMoDesign /></PageWrapper>}
            />
            <Route
              path="/promotion"
              element={<PageWrapper isActive={activePage === "/promotion"}><Promotion /></PageWrapper>}
            />
            <Route
              path="/jackpot"
              element={<PageWrapper isActive={activePage === "/jackpot"}><Jackpot /></PageWrapper>}
            />
            <Route
              path="/coinReward"
              element={<PageWrapper isActive={activePage === "/coinReward"}><CoinRewards /></PageWrapper>}
            />
            <Route
              path="/gamedata"
              element={<PageWrapper isActive={activePage === "/gamedata"}><TableDataInfo /></PageWrapper>}
            />
          </Routes>
        </div>
      )}
      {!isMobile && (
       <Routes>
       <Route path="/" element={<Main />} />
       <Route path="/profile" element={<Profile />} />
       <Route path="/notification" element={<NotificationPage />} />
       <Route path="/information" element={<InformationMoDesign />} />
       <Route path='/promotion' element={<Promotion />} />
       <Route path="/jackpot" element={<Jackpot />} />
       <Route path="/coinReward" element={<CoinRewards />} />
       <Route path="/gamedata" element={<TableDataInfo />} />
     </Routes>
      )}
      <Footer />
    </>
  );
};

const PageWrapper = ({ isActive, children }) => {
  return (
    <div className={`page ${isActive ? "page-active" : ""}`}>
      {children}
    </div>
  );
};

export default NavigationComponent;
